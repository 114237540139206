(function (global, factory) {
    'use strict';

    if (typeof (module) === 'object' && typeof (module.exports) === 'object') {
        /**
         *
         * For CommonJS and CommonJS-like environments where a proper `window`
         * is present, execute the factory and get Main.
         * For environments that do not have a `window` with a `document`
         * (such as Node.js), expose a factory as module.exports.
         * This accentuates the need for the creation of a real `window`.
         * e.g. var main = require('main')(window);
         * @type {void|(function(*): void)}
         */
        module.exports = global.document ?
            factory(global, true) :
            function (w) {
                if (!w.document) {
                    throw new Error('Main requires a window with a document');
                }
                return factory(w, false);
            };
    } else {
        factory(global, false);
    }
})(typeof (window) !== 'undefined' ? window : this, function (window, noGlobal) {
    if (noGlobal) {
        (typeof (window.canvas) === 'undefined') && (window.canvas = {});
        (typeof (window.stage) === 'undefined') && (window.stage = {});
        (typeof (window.exportRoot) === 'undefined') && (window.exportRoot = {});
        (typeof (window.anim_container) === 'undefined') && (window.anim_container = {});
        (typeof (window.dom_overlay_container) === 'undefined') && (window.dom_overlay_container = {});
        (typeof (window.fnStartAnimation) === 'undefined') && (window.fnStartAnimation = {});
    }

    function init() {
        const _LOCATION_ = window.location, _SOURCE_ = '';
        window.canvas = document.getElementById('canvas');
        window.anim_container = document.getElementById('animation_container');
        window.dom_overlay_container = document.getElementById('dom_overlay_container');

        /* Guide */
        createjs.MotionGuidePlugin.install();
        let
            composition = AdobeAn.getComposition(Object.keys(AdobeAn.compositions)[0]),
            library = composition.getLibrary(),
            preload = Boolean(document.getElementById('app').getAttribute('data-preload') === 'true'),
            video = Boolean(document.getElementById('app').getAttribute('data-video') === 'true'),
            uri = `${_LOCATION_.protocol}//${_LOCATION_.host}${_SOURCE_}`;

        if (preload) {
            let
                element_div = document.createElement('DIV'),
                element_img = document.createElement('IMG');

            element_div.setAttribute('id', '_preload_div_');
            element_img.setAttribute('src', `${uri}/public/images/pre-loader.gif`);

            element_div.appendChild(document.createElement('SPAN'));
            element_div.appendChild(element_img);

            document.getElementById('app').appendChild(element_div);
        }

        let content = {
            composition: composition,
            full_screen: Boolean(document.getElementById('app').getAttribute('data-full-screen') === 'true'),
            preload: preload,
            video: video
        };

        if (Boolean(library.properties.manifest.length)) {
            let loader = (new createjs.LoadQueue(false));

            loader.addEventListener('fileload', handleFileLoad.bind({composition: composition}));

            loader.addEventListener('complete', handleComplete.bind(content));

            loader.loadManifest(library.properties.manifest);
        } else {
            handleComplete.bind(content)();
        }
    }

    /**
     *
     * @param event
     */
    function handleFileLoad(event) {
        let images = this.composition.getImages();

        if (event && (event.item.type === 'image')) {
            images[event.item.id] = event.result;
        }
    }

    /**
     *
     * @param event
     */
    function handleComplete(event) {
        /* This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage. */
        let
            library = this.composition.getLibrary(),
            ssMetadata = library.ssMetadata,
            domContainers = [];

        if (Boolean(ssMetadata.length) && typeof (event.target) !== 'undefined') {
            let
                sprite_sheet = this.composition.getSpriteSheet(),
                queue = event.target;

            for (let i = 0; i < ssMetadata.length; i++) {
                sprite_sheet[ssMetadata[i].name] = (new createjs.SpriteSheet({
                    'images': [queue.getResult(ssMetadata[i].name)],
                    'frames': ssMetadata[i].frames
                }));
            }
        }

        if (this.preload) {
            let _preload_div_ = document.getElementById('_preload_div_');
            _preload_div_.style.display = 'none';
            window.canvas.style.display = 'block';
            domContainers.push(_preload_div_)
        }

        if (this.video) {
            domContainers.push(document.getElementById('_video_'));
        }

        window.exportRoot = (new library[library_name]());
        window.stage = (new library.Stage(window.canvas));
        window.stage.enableMouseOver();

        /* Registers the "tick" event listener. */
        window.fnStartAnimation = function () {
            window.stage.addChild(window.exportRoot);
            createjs.Ticker.framerate = library.properties.fps;
            createjs.Ticker.addEventListener('tick', window.stage);
        };

        /* Code to support hidpi screens and responsive scaling. */
        AdobeAn.makeResponsive(true, 'both', true, 1, [window.canvas, ...domContainers, window.anim_container, window.dom_overlay_container]);

        let full_screen = this.full_screen;

        function allTapLoad() {
            if (typeof (AllTap) !== 'undefined') {
                AllTap.onLoad({
                    full_screen: full_screen,
                    library: {[`_this_${library_name}_library`]: library},
                    document: document
                });
                createjs.Ticker.removeEventListener('tick', allTapLoad);
            } else if (typeof (parent.AllTap) !== 'undefined') {
                parent.AllTap.onLoad({
                    library: {[`_this_${library_name}_library`]: library},
                    document: document
                });
                createjs.Ticker.removeEventListener('tick', allTapLoad);
            }
        }

        createjs.Ticker.addEventListener('tick', allTapLoad);
        AdobeAn.compositionLoaded(library.properties.id);
        window.fnStartAnimation();
    }

    window.addEventListener('load', init);
});